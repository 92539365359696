var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5XmAvU0qinwIvkNnbYwCv"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import Cookie from 'js-cookie';

const VERCEL_ENV = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://3881c6f290e54debb7ef50d054c7596f@o894200.ingest.sentry.io/5860551',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampler(samplingContext) {
      if (samplingContext.transactionContext.op === 'pageload') {
        return 0.005;
      }

      return 0.1;
    },
    environment: VERCEL_ENV,
    enabled: VERCEL_ENV === 'production',
    ignoreErrors: [/Not Authorization/i],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
};

// Sentry kaamel 修改 直接Sentry访问
initSentry();

// const flag = Cookie.get('kaamel');
// if (flag === '!otherType=true') {
//   // 如果用户之前已同意访问cookie，则初始化sentry
//   initSentry();
// }

// window.kaamelSetting = {
//   allowAll() {
//     // 如果用户同意访问cookie，则初始化sentry
//     initSentry();
//     console.info(
//       '%cThe user agrees to access cookies. Sentry init.',
//       'color: green; font-size: 20px; background: black;',
//     );
//   },
//   disableAll() {
//     console.info(
//       '%cThe user does not consent to obtaining cookies, nothing to do.',
//       'color: red; font-size: 20px;background: black;',
//     );
//     // nothing to do
//   },
// };
