/**
 * 如果是 edu.net 的域名，返回 edu.net 的 API地址
 * @returns
 */
export const getAPIBaseUrl = (api: string | undefined) => {
  if (!api) {
    return '';
  }
  const isEduUrl = globalThis?.location?.href?.includes?.('.wukongedu.net');
  return isEduUrl ? api.replace('.wukongacademy.com', '.wukongedu.net') : api;
};
