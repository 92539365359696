import React from 'react';

import styles from './style.module.scss';

const PageWrapper: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageWrapper;
